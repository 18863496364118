import React, { useState, useRef, useEffect } from 'react';
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  IonImg,
  IonSearchbar,
  IonIcon,

  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { create, ellipsisHorizontal, ellipsisVertical, helpCircle, search, personCircle, star } from 'ionicons/icons';
import { PindPlans } from './PlansModal'

const FriendsList =  () => (
  <IonList>
    <IonItem>
      <IonAvatar slot="start">
        <IonImg src="https://i.pravatar.cc/300?u=b" />
      </IonAvatar>
      <IonLabel>
        <h2>G milli</h2>
        <p>Oh behave!!!</p>
      </IonLabel>
    </IonItem>
    <IonItem>
      <IonAvatar slot="start">
        <IonImg src="https://i.pravatar.cc/400?img=14" />
      </IonAvatar>
      <IonLabel>
        <h2>Huddy</h2>
        <p>Meow</p>
      </IonLabel>
    </IonItem>
    <IonItem>
      <IonAvatar slot="start">
        <IonImg src="https://i.pravatar.cc/400?img=41" />
      </IonAvatar>
      <IonLabel>
        <h2>AJ Kenny</h2>
        <p>The baddest of bitches</p>
      </IonLabel>
    </IonItem>
    <IonItem>
      <IonAvatar slot="start">
        <IonImg src="https://i.pravatar.cc/400?img=9" />
      </IonAvatar>
      <IonLabel>
        <h2>T Sauce</h2>
        <p>Hot girl court is now in session</p>
      </IonLabel>
    </IonItem>
  </IonList>
)

export function PlansModalCard( {parentRef, isOpen, setIsOpen, plans} ) {
  const modal = useRef(null);
  const page = parentRef //useRef(null);

  const [presentingElement, setPresentingElement] = useState(null);

  useEffect(() => {
    console.log(page,page.current)
    setPresentingElement(page.current);
  }, []);

  function dismiss() {
    //setIsOpen(false)
    console.log('dismissd')
    modal.current?.dismiss();
  }

  const [appTitle, setAppTitle] = useState('NYC');
  const togglePlansMenu = () => {
    const inputPrompt = prompt("What's your homebase?", appTitle)
    if (inputPrompt) {
      setAppTitle(inputPrompt)
    }
  }

  const [activeTab, setActiveTab] = useState('favorites');

  const handleSegmentChange = (event) => {
    setActiveTab(event.detail.value);
  };

  const ModalToolbar = () => (
    <IonHeader>
    <IonToolbar>
    {/* <div style={{width:'100%', minHeight:'10px'}}></div> */}
      {/* <IonTitle>Friends</IonTitle>
      <a style={{pointerEvents: true}} onClick={() => setIsOpen(false)}>Close</a>
      <button onClick={() => console.log('dissy')}>parClose</button>
      <IonButton onClick={dismiss}>Close</IonButton>*/}

      <IonButtons>
        <IonButton onClick={togglePlansMenu}>{appTitle}</IonButton>
      </IonButtons> 
      {/* <IonSearchbar placeholder="Search Favorites" showCancelButton="focus"/>*/}
      <IonButtons slot="end">
        <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
        {/* <IonButton onClick={() => console.log('clk')}>
          <IonIcon slot="icon-only" icon={personCircle}></IonIcon>
        </IonButton> */}
      </IonButtons>  
      
      <IonSegment value={activeTab} onIonChange={handleSegmentChange}>
        <IonSegmentButton value="friends">
          <IonLabel>Friends</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="favorites">
          <IonLabel>Favorites</IonLabel>
        </IonSegmentButton>
      </IonSegment>

    </IonToolbar>
    </IonHeader>
  )

  const toggleProfileModal = () => {
    setActiveTab(activeTab == 'friends' ? 'favorites' : 'friends');
  };

  const [searchText, setSearchText] = useState('');

  return (
    // <IonPage ref={page}>
    //   <IonHeader>
    //     <IonToolbar>
    //       <IonTitle>App</IonTitle>
    //     </IonToolbar>
    //   </IonHeader>
    //   <IonContent className="ion-padding">
    //     <IonButton id="open-modal" expand="block">
    //       Open
    //     </IonButton>
        <IonModal ref={modal} canDismiss={true}
          isOpen={isOpen}//
          onDidDismiss={() => setIsOpen(false)}
          //trigger="open-modal" 
          //presentingElement={presentingElement}
          breakpoints={[0, 0.5, 0.75, 1.0]}
          initialBreakpoint={0.75}
          handleBehavior="cycle"
        >
          {/* <ModalToolbar/> */}
          <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
            <IonSearchbar 
              placeholder="Search Favorites" 
              showCancelButton="focus"
              value={searchText}
              onIonInput={(e) => setSearchText(e.detail.value || '')}
            />
            <IonIcon style={{marginRight: '8px'}} size="large" color="primary" icon={personCircle} onClick={toggleProfileModal}></IonIcon>
          </div>
          <IonContent className="ion-padding">
            {
              activeTab == 'friends' ? <FriendsList/> : <PindPlans plans={plans} searchValue={searchText}/>
            }
          </IonContent>
        </IonModal>
    //   </IonContent>
    // </IonPage>
  );
}
