
import Modal, {ModalHeader, ModalSection, ModalFooter } from "@kiwicom/orbit-components/lib/Modal";
import Button from "@kiwicom/orbit-components/lib/Button";
import Itinerary from "@kiwicom/orbit-components/lib/Itinerary";
import { FlightSegment } from './Segments';
import { fetchSearch, DetailsButton, ItineraryList } from './ItineraryList';

export const PindPlans = ( {plans, searchValue} ) => {
  //console.log(plans)
  //const allSegments = plans.flatMap(obj => obj[segments]);
  //const pind_ids={item.pind_ids ? item.pind_ids.split(',') : []}

  const SegsStr = localStorage.getItem('segments')
  const segments = JSON.parse(SegsStr) || {}
  const allPindIds = plans.flatMap(item => item.planDetails.pind_ids ? item.planDetails.pind_ids.split(',') : []);

  const pindSegments = Object.fromEntries(
    Object.entries(segments).filter(([key]) => allPindIds.includes(key))
  );
  console.log(segments, pindSegments, searchValue)
  return (
    <div style={{colorScheme: 'light dark' //, backgroundColor: 'light-dark(white, #999999)'

    }}>
    <ItineraryList 
      items={allPindIds.filter(key => key in segments).map(key => segments[key]).filter((s) => new RegExp(`.*${searchValue}.*`, 'i').test(s.name) )}
      //type="place"
      //pind_ids={item.pind_ids ? item.pind_ids.split(',') : []}
      //updatePins={(pind_arr) => inputsUpdater({...item, pind_ids: pind_arr.join(',')})}
    />
    </div>
)}

export const PindPlans2 = ( {plans} ) => (
  <Itinerary>
    <div style={{colorScheme: 'light dark', backgroundColor: 'light-dark(white, #999999)'}}
    > 
      {
        plans
          .toReversed()
          .flatMap(obj => Object.values(obj.planDetails?.addlDetails?.pins || {}))
          .map((segment, i) => {
            console.log(segment)
            return segment && <FlightSegment key={i} item={segment}/>
          })
      }
    </div>
  </Itinerary>
)

export const PlansModal = ( {plans, setShowPlusModal} ) => {

  return (
    <Modal
      onClose={(e) => {
        setShowPlusModal(false);
        e.stopPropagation(); 
      }}
    >
      <ModalHeader
        title='Plans'
        //description='Likes from across all your plans live here :)'
      />
      <ModalSection>

      <PindPlans plans={plans}/>

      </ModalSection>
      <ModalFooter>
        <Button 
          fullWidth={true} 
          submit={true}
          //onClick={console.log('Share clicked')}
        >
        Share
        </Button>
      </ModalFooter>
    </Modal>
  );
};