import React, { useEffect, useState, useRef } from "react";
import moment from 'moment'
import { fetchSearch, DetailsButton, ItineraryList } from './ItineraryList';
import { headerRowStyle } from './Header';
import { ShareButton } from './components/ShareButton';
import { PinButton, PinButtonFilled, PindButton } from './_local/PinButton';
import Itinerary, {
  ItinerarySegment,
  ItineraryStatus,
  ItineraryBadgeList,
  ItinerarySegmentStop,
  ItinerarySegmentDetail,
} from "@kiwicom/orbit-components/lib/Itinerary";
import Tile from "@kiwicom/orbit-components/lib/Tile";
import Badge from "@kiwicom/orbit-components/lib/Badge";
import BadgeList from "@kiwicom/orbit-components/lib/BadgeList";
import CarrierLogo from "@kiwicom/orbit-components/lib/CarrierLogo";
import * as Icons from "@kiwicom/orbit-components/icons";
import Stack from "@kiwicom/orbit-components/lib/Stack";
import Heading from "@kiwicom/orbit-components/lib/Heading";
import CountryFlag from "@kiwicom/orbit-components/lib/CountryFlag";
import Text from "@kiwicom/orbit-components/lib/Text";
import Modal, {ModalHeader, ModalSection } from "@kiwicom/orbit-components/lib/Modal";

export const HostNameLink = ( {url} ) => {
    const hostname = new URL(url).hostname
    return (
      <a href={url}>{hostname}</a>
    )};

//still needs to be put in a tile for proper layout
const SegmentHeader = ( {title, meta, countryToCode} ) => (
    <Stack align="center" direction="row" justify="between" shrink>
      <Stack direction="column" shrink spacing="none">
        <Stack align="center" direction="row" spacing="XSmall">
          {/*title3 to match "title" font size, as="h4" seems to do nothing?*/}
          <Heading type="title4">{title}</Heading>
          {countryToCode && <CountryFlag code={countryToCode} />}
        </Stack>
      </Stack>
        <Stack align="center" basis="0%"><Badge type="info">{meta}</Badge>
      </Stack>
    </Stack>
    );

const SegmentTile = ( {icon, title, meta, body, countryToCode, onClick} ) => { //BaseSegment

  return (
    <div style={{margin: '1px 0vh 0vh'}}>
      <Tile 
        onClick={onClick}
        expandable={!onClick} //expanded
        noPadding={true}
        icon={icon}
        header={
          <SegmentHeader 
            title={title}
            meta={meta}
            countryToCode={countryToCode ?? ''}
          />}
      >
      {body}
      </Tile>
    </div>
    );
}

function getCarriersBadges(airlines) {
  //do multiple carries need multiple badges?
  /*carriers={[
    {
      code: item.airlines[0],
      name: "Ryanair",
    },
    {
      code: item.airlines[1],
      name: "Ryanair2",
    },
  ]}*/
  return airlines.map(a => (<Badge carriers={[{code: a}]}/>))
}

function getNumStops(routeLength) {
  if (routeLength === 1) {
    return "Direct"
  }
  if (routeLength === 2) {
    return (routeLength - 1) + " stop" 
  }
  if (routeLength > 2) {
    return (routeLength - 1) + " stops"
  }
}

export const PindIcon = ( {pinUpdater, pind=false} ) => {
  const divRef = useRef(null);
  if (divRef.current) divRef.current.style.animation =  '';

  return (
  <div
    style={{
      fontWeight: '700', 
      color: 'var(--secondary-input)',
      // fontSize: '17px', 
      }}
    ref={divRef}
    onClick={(e) => {
      divRef.current.style.animation =  'pulse 1s infinite';
      e.preventDefault();
      pinUpdater()
    }}
  >
    {pind ? '♥': '♡'}
  </div>
)}

export const FlightSegment = ( {item, pinUpdater, pind=false} ) => (
    <ItinerarySegment spaceAfter="none">
          <ItinerarySegmentStop
            city={item.cityFrom}
            station={item.flyFrom}
            date={moment(item.local_departure, "YYYY-MM-DD hh:mm").format("ddd, MMM D")}
            time={moment(item.local_departure, "YYYY-MM-DD hh:mm").format("h:mm A")}
          />
          <ItinerarySegmentDetail
            duration={moment("1900-01-01").startOf('day').seconds(item.duration.total || item.duration).format("h[h] m[m]")}
            summary={
              <div>
              <Badge carriers={item.airlines.map(a => ({code: a}))}>
                {getNumStops(item.route.length)}
              </Badge>
              {pinUpdater && <Badge><PindIcon pinUpdater={pinUpdater} pind={pind}/></Badge>}
              {!!item.link && <Badge><a href={item.link}>${item.fare.adults}</a></Badge>}
              {/* {<Badge><a href={item.link}>{item.route[0].airline + item.route[0].flight_no}</a></Badge>} */}
              </div>
            }
            /*content={[
              {
                title: "Connection Info",
                items: [
                  {
                    icon: <Icons.Airplane size="small" />,
                    name: "Carrier",
                    value: "Ryanair",
                  },
                  {
                    icon: <Icons.InformationCircle size="small" />,
                    name: "Connection number",
                    value: "RA 8345",
                  },
                ],
              },
              {
                title: "Seating Info",
                items: [
                  {
                    icon: <Icons.Wifi size="small" />,
                    name: "Wi-Fi on board",
                    value: "No",
                  },
                ],
              },
            ]}*/
          />
          <ItinerarySegmentStop
            city={item.cityTo}
            station={item.flyTo}
            date={moment(item.local_arrival, "YYYY-MM-DD hh:mm").format("ddd, MMM D")}
            time={moment(item.local_arrival, "YYYY-MM-DD hh:mm").format("h:mm A")}
          />
        </ItinerarySegment>
    );

export const FlightsSegment = ( {item} ) => (

  <ItinerarySegment spaceAfter="medium">  
  {
  item.route.map( (segment, i) => {
    const stopDt = (segment.takeoff || segment.local_departure)

    return <div key={i}>
    {
      "duration" in segment 
      ? 
      <ItinerarySegmentDetail
        duration={moment("1900-01-01 "+segment.duration, "YYYY-MM-DD hh:mm").format("h[h] m[m]")}
        summary={<Icons.Ticket size="small"/>}>
      </ItinerarySegmentDetail>
      : 
      <ItinerarySegmentStop
        city={segment.city}
        station={segment.station}
        date={moment(stopDt, "YYYY-MM-DD hh:mm").format("ddd, MMM D")}
        time={moment(stopDt, "YYYY-MM-DD hh:mm").format("h:mm A")}
      />
    }
    </div>
  })}

  </ItinerarySegment>
);

export const AccommodationSegment = ( {item} ) => (
  <SegmentTile
    icon={<Icons.Accommodation />}
    title={item.toStation}
    meta={moment(item.end_date, "YYYY-MM-DD").diff(moment(item.start_date, "YYYY-MM-DD"), 'days') + " Days/ Nights"}
    body={
        <Tile
          expandable
          title={item.price}
          description={<HostNameLink url={item.link}/>}
          icon={<img src={item.img} alt="IMG" width="64" height="64"/>}
        >
          Check In: {moment(item.start_date, "YYYY-MM-DD hh:mm").format("LT")}
        </Tile>
    }
  />
  );

export const EventSegment = ( {item} ) => (
  <SegmentTile
    icon={<Icons.Calendar />}  //TicketOutline
    title={item.toStation + " @ " + item.cityCodeTo}
    meta={moment(item.start_date, "YYYY-MM-DD hh:mm").format("ddd, MMM D")}
    body={
      <EventBody 
      {...{
        icon: <img src={item.img} alt="IMG" width="64" height="64"/>,
        heading: item.price,
        subHeading: <HostNameLink url={item.link}/>,
        link: item.resy_url,
        addlElement: item.who.split(",").map( (el, idx) => <Badge key={idx} type="info">{el}</Badge>),
        details: `Enter after: ${moment(item.start_date, "YYYY-MM-DD hh:mm").format("LT")}`
      }}
      />
    }
  />
);

export const EventBody = ( {icon, heading, subHeading, link, addlElement, details} ) => (
<Tile
  expandable={!link}
  icon={icon}
  href={link}
  header={
    <Stack align="center" direction="row" justify="between" shrink>
      <Stack direction="column" shrink spacing="none">
        <Stack align="center" direction="row" spacing="XSmall">
          <Heading as="h4" type="title4">{heading}</Heading>
        </Stack>
        <Text>{subHeading}</Text>
      </Stack>
      <Stack spacing="XXXSmall">
      {addlElement}
      </Stack>
    </Stack>}
>
{details}
</Tile> 
);

const getSpecificResyLink = ( slot, seats ) => {
  const searchParams = new URLSearchParams();

  slot.config.time = slot.date.start
  searchParams.append("reservation", JSON.stringify(slot.config));

  const startDatetime = moment(slot.date.start)
  searchParams.append("date", startDatetime.format("YYYY-MM-DD"));

  searchParams.append("seats", seats);

  return 'https://widgets.resy.com/#/reservation-details?' + searchParams.toString();
}

export const ResSegment = ( {item} ) => (
<SegmentTile
  icon={<Icons.Calendar/>} //refund //cocktail
  title={item.name}
  meta={moment(item.good_slots[0].date.start, "YYYY-MM-DD hh:mm").format("h:mma")}
  body={
      <EventBody 
      {...{
        icon: <img src={item.preview_image_url} alt="IMG" width="64" height="64"/>,
        heading: `★ ${item.resy_rating.toFixed(1)} (${item.resy_num_ratings}) ⸱ ${'$'.repeat(item.dollar_signs)}`,
        subHeading: `📍${item.neighborhood}`,
        link: getSpecificResyLink(item.good_slots[0], item.seats),//item.resy_url,
        details: <div>
          {item.good_slots.map( (el, idx) => 
            <Badge key={idx} >{/*onClick={(e) => {e.preventDefault(); resyWidget(el, item.seats)}}>*/}
              {moment(el.date.start, "YYYY-MM-DD hh:mm").format("h:mma")}
            </Badge> 
          )}
          <div>{item.about}</div>
          </div>
      }}
      />
  }
/>
)

export const PlaceSegment = ( {item, pinUpdater, pind=false} ) => (
  // <details style={{borderBottom: '1px solid #ccc', margin:'0px 12px', color: 'var(--text-color)'}}>
  <details style={{borderBottom: '1px solid var(--label-color)', margin:'0px 12px', color: 'var(--text-color)'}}>
  
  <summary style={{ ...headerRowStyle}}>
  <img src={item.preview_image_url} alt="IMG" width="64" height="64" 
       style={{borderRadius:8, margin:'8px 0px', objectFit: 'cover'}}
  />
  <div>
    <div style={{fontWeight: 500}}>{item.name}</div>
    <div style={{...headerRowStyle, fontSize: '0.875rem', color: 'var(--label-color)', height: '100%'}}>
    {`★ ${item.resy_rating.toFixed(1)} ⸱ ${'$'.repeat(item.dollar_signs)}`}
    <ShareButton title={item.name} url={item.resy_url} diameter={12}/>
    <PindIcon pinUpdater={pinUpdater} pind={pind}/>
    </div>
  </div>
  <a href={item.resy_url} style={{marginLeft: 'auto', textDecoration: 'none', color: 'var(--input-color)'}}>
    {moment(item.good_slots[0].date.start, "YYYY-MM-DD hh:mm").format("h:mma")}
  </a>
  </summary>
  
  <div style={{padding: '6px 12px', colorScheme: 'none'}}>
  {item.good_slots.map( (el, i) => 
    <input key={i} type="button" value={moment(el.date.start, "YYYY-MM-DD hh:mm").format("h:mma")} 
      onClick={() => window.location.href = getSpecificResyLink(el, item.seats)}
    />
  )}
  <br></br>
  {item.about}
  {/* {console.log(item.neighborhood, item.cuisine, item.resy_num_ratings, item.lat/long)} */}
  </div>

  </details>
)

export const ResBody = ( {plan} ) => {
  const { planDetails: item, planUpdater: inputsUpdater, ...planProps } = plan;
  const searchParams = new URLSearchParams();
  //searchParams.append("city", item.cityFrom); //New York
  searchParams.append("res_date",  item.res_date);
  searchParams.append("party_size", item.party_size);
  searchParams.append("time_start", item.time_start); 
  searchParams.append("time_end", item.time_end);
  searchParams.append("location", item.location);
  searchParams.append("search_radius", 1);
  searchParams.append("cuisine", item.cuisine || "");
  //party_size=8&res_date=2024-02-22&city=New+York&time_start=19%3A00&time_end=20%3A00&location=&search_radius=1&collection=&cuisine=&seating_type=

  //const url = '/testres' 
  const url =  '/v1/searchres?' + searchParams.toString();

  const [status, setStatus] = useState('');
  const [list, setList] = useState([]);
  const setListCached = (data) => {
    const segs = Object.fromEntries(data.results.map(seg => 
      [seg.id, {...seg, type: 'place'}]
    ));
    localStorage.setItem('segments',JSON.stringify(segs));
    setList(data);
  }

  const shouldFetch = (list.length == 0) && !!inputsUpdater; //isCardOpen
  useEffect(()=>{
    if ( !(item.res_date && item.location) ) {
      return setStatus('Pick a time and place and find a res !')
    }

    shouldFetch && fetchSearch(url, setStatus, setListCached)
  }, [url, shouldFetch]);

  return (
      <>
        <DetailsButton 
          description={list.description || status /*ⓘ*/ }
          defaultOpen={!list.description}  
        />
        {status === 'Success' && 
          <ItineraryList 
            items={list.results.map(obj => ({...obj,seats: item.party_size}))}
            type="place"
            pind_ids={item.pind_ids ? item.pind_ids.split(',') : []}
            updatePins={(pind_arr) => inputsUpdater({...item, pind_ids: pind_arr.join(',')})}
          />
        }
      </>
  );
}

/* To Add - PIND + 
1. alernative slot count on link
2. button instead of a/href?
3. Info as a dialog instead of expand
4. Other HTML Goodies: radius slider, colorPicker, progress spinner, darkMode/toggle, shareButton, and multiSelect?
5. file for pic / prompt for planName?
6. unstretch images?
//no sign audio is playing and then says Lets Gooo randomly

USVI, Jacks, Joeys X2, NYE, Super Bowl
*/
/*
DetailsButton to show list plan editor
-so that we can get rid of ListAdder
*/

function getPercentOfDay(seconds) {
  return Math.floor(seconds / (24*60*60) * 100);
}

function getSecondsSinceMidnight(dt) {
  const m = moment(dt, "YYYY-MM-DD HH:mm");
  return m.diff(m.clone().startOf('day')) / 1000;
}

function formatDurationAsHoursMins(seconds) {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);

  return `${hours}h ${minutes}m`;
}

export const FlightResultSegment = ( {item, pinUpdater, pind=false} ) => {
  const departureDT = moment(item.local_departure, "YYYY-MM-DD HH:mm");
  const arrivalDT = moment(item.local_arrival, "YYYY-MM-DD HH:mm");
  
  return (
  <div key={item.id}>
    <details style={{padding: '12px 0px', borderBottom: '1px solid #ccc', margin:'0px 12px', color: 'var(--text-color)'}}>
    
    <summary style={headerRowStyle}>
    <div style={{width: '75%'}}>
      <div style={{...headerRowStyle}}>
        <div style={{fontSize: '11px', fontWeight: 500}}>{item.cityFrom}</div>
        <div style={{flexGrow: 1, textAlign: 'center'}}>
          {/* Sun, Jun 1 */}
        </div>
        <div style={{fontSize: '11px', fontWeight: 500}}>{item.cityTo}</div>
      </div>

      <div style={{...headerRowStyle}}>
        <div style={{fontSize: '30px'}}>{item.flyFrom}</div>
        <div style={{width: '100%'}}>
          <div style={{flexGrow: 1,display: 'flex',flexFlow: 'row',justifyContent: 'center'}}>
          {item.airlines.map((a, idx) => (
            <CarrierLogo key={idx} carriers={[{code: a}]} size="small" rounded/>
          ))}
          </div>
          <div style={{flexGrow: 1, backgroundColor: 'rgba(0,0,0,0.2)', maxHeight: '8px', borderRadius: '4px'
            ,display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
          }}>
            <div style={{backgroundColor: 'var(--input-color)', minHeight: '6px', borderRadius: '4px',
              width: `${getPercentOfDay(item.duration.total || item.duration)}%`,
              marginLeft: `${getPercentOfDay(getSecondsSinceMidnight(item.local_departure))}%`}}>
            {/* <CarrierLogo carriers={[{code: a}]} size="small" rounded/> */}
          </div>
          </div>
          {(!departureDT.isSame(arrivalDT, 'day')) &&
            <div style={{marginLeft: '90%'}}>+1</div>}
        </div>
        <div style={{fontSize: '30px'}}>{item.flyTo}</div>
      </div>

      <div style={{...headerRowStyle, width: '100%', fontSize: '0.875rem', color: 'var(--label-color)', height: '100%'}}>
        <div>{moment(item.local_departure, "YYYY-MM-DD hh:mm").format("h:mm A")}</div>
        <div style={{flexGrow: 1, textAlign: 'center'}}>
          {formatDurationAsHoursMins(item.duration.total || item.duration)}
        </div>
        <div>{moment(item.local_arrival, "YYYY-MM-DD hh:mm").format("h:mm A")}</div>
      </div>
    </div>
    <a href={item.resy_url} style={{margin: '0 auto', textDecoration: 'none', color: 'var(--input-color)'}}>
      ${item.fare.adults}
    </a>
    </summary>
    {<div>
      <FlightSegment item={item} pinUpdater={pinUpdater} pind={pind}/>
    </div>}
    </details>
  </div>
  )
}

export const FlightResultSegment2 = ( {item, pinUpdater, pind=false} ) => {
  return (
  <div key={item.id}>
    
    <FlightSegment item={item} pinUpdater={pinUpdater} pind={pind}/>
    <div style={{ position: 'relative', float: 'right', left: '-30px', top: '-85px' }}>

      {/* <button onClick={() => pinUpdater()}
      style={{ margin: '2px 0px', width: '32x', height: '32px', fill: item.pind ? 'rgb(235, 184, 0)': '', transition: 'fill 2000ms linear'}}>
        <PindButton pind={pind}/>
      </button> 

      <button 
        onClick={() => pinUpdater()}
        style={{ margin: '2px 0px', width: '32x', height: '28px', paddingInline: '0', fontSize: '17px', fontWeight: '700', color: 'rgb(235, 184, 0)'
          //height larger than 28 kills safari button styling, previously 32
          //, backgroundColor: item.pind ? 'rgb(235, 184, 0)': 'transparent'
          //, transition: 'background-color 2000ms linear'
        }}
        type={pind ? "submit": ""}
      >
        {pind ? '♥': '♡'}
      </button>*/}

      <button 
        onClick={() => {window.location.href = item.deep_link;}}
        style={{ margin: '2px 0px', width: '64px', height: '28px', fontSize: '14px' }}
        type="submit"
      >
        ${item.fare.adults}
      </button>
    </div>
  </div>
  )
}

export const SearchBody = ( {plan} ) => {
  const { planDetails: item, planUpdater: inputsUpdater, ...planProps } = plan;
  const searchParams = new URLSearchParams();
  searchParams.append("origin", item.cityCodeFrom);
  searchParams.append("destination", item.cityCodeTo);
  searchParams.append("start_date", item.start_date)
  if (item.flexible) {
    item.end_date = moment(item.start_date, "YYYY-MM-DD").add(item.flexible, 'days').format("YYYY-MM-DD");
  }
  searchParams.append("end_date", item.end_date || item.start_date)
  searchParams.append("num_passengers", item.adults || 1);
  searchParams.append("selected_cabins", item.selected_cabins || "M");
  searchParams.append("max_stops", item.max_stops || 20);
  searchParams.append("flight_type", "oneway"); //TODO when multistop is added

  const url = '/v1/searchflights?' + searchParams.toString();

  const [status, setStatus] = useState('');
  const [list, setList] = useState([]);
  const shouldFetch = (list.length == 0) && !!inputsUpdater; //isCardOpen

  useEffect(()=>{
    if ( !(item.cityCodeFrom && item.cityCodeTo && item.start_date) ) {
      return setStatus()//('Fill out the required details above to get going!')
    }

    if (moment(item.end_date, "YYYY-MM-DD hh:mm") < moment().endOf('day')) {
      return setStatus('Invalid request (end date must not be in past)')
    }

    shouldFetch && fetchSearch(url, setStatus, setList)
  }, [url, shouldFetch]);

  function getSegmentID(segment){
    //return segment.id //segment.route[0].utc_departure/combination_id) //id needs date for uniqueness across days
    return `${segment.route[0].airline}${segment.route[0].flight_no}`
  }

  function savePins(pind_arr) {
    const pindSegments = list.data.filter(seg => pind_arr.includes(seg.id))
    const toggledPins = Object.fromEntries(pindSegments.map(seg => [seg.id, seg]));

    //return {addlDetails: {pins: toggledPins}, pind_ids: toggledIds.join(',')}
    console.log({addlDetails: {pins: toggledPins}, pind_ids: pind_arr.join(',')})

    inputsUpdater({...item, pind_ids: pind_arr.join(',')})
  }

  const FlightSearchInfo = () => (
    <>
    <div><i>Let's Go</i> is the ultimate 1-way flight finder.</div>
    <ol style={{textAlign: 'left'}}>
      <li>Choose a destination city/airport ("To")</li>
      <li>Select a date ("When?")</li>
      <li>Add modifiers, like flexible dates or stops</li>
    </ol>
    {status !== 'Success' &&  <div style={{textAlign: 'center'}}><b>{status}</b></div>}
    </>
  )

  return (
    <>
      <DetailsButton 
        description={<FlightSearchInfo/>}
        defaultOpen={status !== 'Success'}  
      />
      {status === 'Success' &&
      <ItineraryList 
        items={list.data}
        type="flightResult"
        pind_ids={item.pind_ids ? item.pind_ids.split(',') : []}
        updatePins={(pind_arr) => inputsUpdater({...item, pind_ids: pind_arr.join(',')})}
      />
      }
    </>
  );
}

export const SearchSegment = ( {item} ) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
  <SegmentTile
    onClick={() => setShowModal(true)}
    icon={<Icons.Airplane/>}
    title={item.toStation}
    meta={moment(item.start_date, "YYYY-MM-DD hh:mm").format("ddd, MMM D")}
    countryToCode={item.countryToCode}
    body={showModal && (
      <Modal
        onClose={(e) => {
          setShowModal(false);
          e.stopPropagation();
        }}
      >
        <ModalHeader
          title={item.cityCodeFrom + ' → ' + item.cityCodeTo}
          description={moment(item.start_date, "YYYY-MM-DD hh:mm").format("ddd, MMM D")}
        />
        <ModalSection>
        <SearchBody item={item}/>
        </ModalSection>
        
      </Modal>
    )       
    }
  />

  );
  }

export const ExpenseSegment = ( {item} ) => {
  const expensesNetted = item.ex_owes != 0 ? `(${item.ex_owed}) - (${item.ex_owes})` : `(${item.ex_owed})`
  return (
  <SegmentTile
    icon={<Icons.CreditCard />} //refund //cocktail
    title={item.owed + " to request " + item.owes + ":"}//{item.owes + " -> " + item.owed}
    meta={"$" + item.splitNetted}
    body={
        <Tile
          title={"Net owed for:"}
          description={expensesNetted}
          icon={<img src={item.img} alt="IMG" width="64" height="64"/>}
          href={`venmo://paycharge?txn=charge&recipients=${
              item.owes_venmo}&amount=${
                  item.splitNetted}&note=${
                      expensesNetted}`}
        >
        </Tile>
    }
  />
  );
}

export const segmentTypes = {
  'flight': FlightSegment,
  'flight_segment': FlightsSegment,
  'accommodation': AccommodationSegment,
  'event': EventSegment,
  'flightResult': FlightResultSegment,
  'place': PlaceSegment,
  'expense': ExpenseSegment,
  'search': SearchSegment
}